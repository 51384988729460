import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    annotations: [],
    annotationsStatic: [],
    appData: null,
    appDataToml: null,
    appObligationsData: [],
    appObligationFields: [],
    article: null,
    articles: [],
    assuranceSelected: [],
    brand: 'Regulatory Horizon',
    cases: [],
    countrySelected: [],
    datasetSelected: [],
    entitySelected: [],
    experts: [],
    expertsSelected: [],
    initiatives: [],
    initiativesPolicies: [],
    initiativesPoliciesShow: [],
    initiativesStaticData: null,
    initiativeWorkflowSelected: [],
    key: null,
    mandatorySelected: [],
    message: null,
    notifications: [],
    processes: [],
    policies: [],
    policiesSelected: [],
    regulatorSelected: [],
    reportingmi: [],
    reportingframeworkSelected: [],
    reportingfrequencySelected: [],
    rows: [],
    rowsShow: [],
    sanctions: [],
    search: '',
    sessionShort: 0,
    showAutoTagsBadge: true,
    showInitiatives: [],
    statusSelected: [],
    sortDirection: null,
    sortProperty: null,
    sortPropertyOptions: [],
    sources: [],
    taxonomy: [],
    topicSelected: [],
    trainingcases: [],
    triggerArticleEditorRefresh: null,
    triggerArticleSave: null,
    user: {},
    username: '',
    userState: '',
    windowBottom: false,
    workflowStage: 0
  },
  getters: {
    policiesShow (state) {
      let result = state.policies
      if (state.search.length > 2) {
        result = result.filter(item => item.name.toUpperCase().includes(state.search.toUpperCase()))
      }
      return result
    }
  },
  mutations: {
    setAnnotations (state, payload) {
      state.annotations = payload
    },
    setAnnotationsStatic (state, payload) {
      state.annotationsStatic = payload
    },
    setAppData (state, payload) {
      state.appData = payload
    },
    setAppDataToml (state, payload) {
      state.appDataToml = payload
    },
    setAppObligationsData (state, payload) {
      state.appObligationsData = payload
    },
    setAppObligationsFields (state, payload) {
      state.appObligationFields = payload
    },
    setArticle (state, payload) {
      state.article = payload
    },
    setArticles (state, payload) {
      state.articles = payload
    },
    setAssuranceSelected (state, payload) {
      state.assuranceSelected = payload
    },
    setBrand (state, payload) {
      state.brand = payload
    },
    setCases (state, payload) {
      state.cases = payload
    },
    setCountrySelected (state, payload) {
      state.countrySelected = payload
    },
    setDatasetSelected (state, payload) {
      state.datasetSelected = payload
    },
    setEntitySelected (state, payload) {
      state.entitySelected = payload
    },
    setExperts (state, payload) {
      state.experts = payload
    },
    setExpertsSelected (state, payload) {
      state.expertsSelected = payload
    },
    setInitiatives (state, payload) {
      state.initiatives = payload
    },
    setInitiativesPolicies (state, payload) {
      state.initiativesPolicies = payload
    },
    setInitiativesPoliciesShow (state, payload) {
      state.initiativesPoliciesShow = payload
    },
    setInitiativesStaticData (state, payload) {
      state.initiativesStaticData = payload
    },
    setMandatorySelected (state, payload) {
      state.mandatorySelected = payload
    },
    setKey (state, payload) {
      state.key = payload
    },
    setInitiativeWorkflowSelected (state, payload) {
      state.initiativeWorkflowSelected = payload
    },
    setMessage (state, payload) {
      state.message = payload
    },
    setNotifications (state, payload) {
      state.notifications = payload
    },
    setPolicies (state, payload) {
      state.policies = payload
    },
    setPoliciesSelected (state, payload) {
      state.policiesSelected = payload
    },
    setProcesses (state, payload) {
      state.processes = payload
    },
    setRegulatorSelected (state, payload) {
      state.regulatorSelected = payload
    },
    setReportingframeworkSelected (state, payload) {
      state.reportingframeworkSelected = payload
    },
    setReportingfrequencySelected (state, payload) {
      state.reportingfrequencySelected = payload
    },
    setReportingmi (state, payload) {
      state.reportingmi = payload
    },
    setRows (state, payload) {
      state.rows = payload
    },
    setRowsShow (state, payload) {
      state.rowsShow = payload
    },
    setSanctions (state, payload) {
      state.sanctions = payload
    },
    setShowAutoTagsBadge (state, payload) {
      state.showAutoTagsBadge = payload
    },
    setShowInitiatives (state, payload) {
      state.showInitiatives = payload
    },
    setSortDirection (state, payload) {
      state.sortDirection = payload
    },
    setSortProperty (state, payload) {
      state.sortProperty = payload
    },
    setSortPropertyOptions (state, payload) {
      state.sortPropertyOptions = payload
    },
    setStatusSelected (state, payload) {
      state.statusSelected = payload
    },
    setSources (state, payload) {
      state.sources = payload
    },
    setTaxonomy (state, payload) {
      state.taxonomy = payload
    },
    setTopicSelected (state, payload) {
      state.topicSelected = payload
    },
    setTrainingcases (state, payload) {
      state.trainingcases = payload
    },
    setTriggerArticleEditorRefresh (state, payload) {
      state.triggerArticleEditorRefresh = payload
    },
    setTriggerArticleSave (state, payload) {
      state.triggerArticleSave = payload
    },
    setSearch (state, payload) {
      state.search = payload
    },
    setSessionShort (state, payload) {
      state.sessionShort = payload
    },
    setUser (state, payload) {
      state.user = payload
    },
    setUsername (state, payload) {
      state.username = payload
    },
    setUserState (state, payload) {
      state.userState = payload
    },
    setWindowBottom (state, payload) {
      state.windowBottom = payload
    },
    setWorkflowStage (state, payload) {
      state.workflowStage = payload
    }
  }
})

export default store
