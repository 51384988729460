import Config from './config/config'

const awsmobileCloud = {
  'aws_project_region': 'eu-west-1',
  'aws_cognito_identity_pool_id': Config.AWS_IDENTITY_POOL_ID,
  'aws_cognito_region': Config.AWS_REGION,
  'aws_user_pools_id': Config.AWS_USER_POOL_ID,
  'aws_user_pools_web_client_id': Config.AWS_CLIENT_ID,
  'aws_cloud_logic_custom': [
    {
      'name': 'cosmos',
      'endpoint': Config.API_ENDPOINT,
      'region': 'eu-west-1',
      'custom_header': () => {
        return { user: window.localStorage.getItem('username') }
      }
    },
    {
      'name': 'sql',
      'endpoint': Config.API_SQL_ENDPOINT,
      'region': 'eu-west-1',
      'custom_header': () => {
        return { user: window.localStorage.getItem('username') }
      }
    }
  ]
}

const awsmobileFrmCloud = {
  'aws_project_region': 'us-east-1',
  'aws_cognito_identity_pool_id': Config.AWS_IDENTITY_POOL_ID,
  'aws_cognito_region': Config.AWS_REGION,
  'aws_user_pools_id': Config.AWS_USER_POOL_ID,
  'aws_user_pools_web_client_id': Config.AWS_CLIENT_ID,
  'aws_cloud_logic_custom': [
    {
      'name': 'cosmos',
      'endpoint': Config.API_ENDPOINT,
      'region': 'eu-west-1'
    },
    {
      'name': 'sql',
      'endpoint': Config.API_SQL_ENDPOINT,
      'region': 'eu-west-1'
    }
  ]
}

const awsmobileLocal = {
  'aws_project_region': 'us-east-1',
  'aws_cloud_logic_custom': [
    {
      'name': 'cosmos',
      'endpoint': 'http://localhost:8081',
      'region': 'eu-west-1',
      'custom_header': () => {
        return { user: window.localStorage.getItem('username') }
      }
    },
    {
      'name': 'sql',
      'endpoint': 'http://localhost:8081',
      'region': 'eu-west-1',
      'custom_header': () => {
        return { user: window.localStorage.getItem('username') }
      }
    }
  ]
}

const awsmobileLocalExternal = {
  'aws_project_region': 'us-east-1',
  'aws_cloud_logic_custom': [
    {
      'name': 'cosmos',
      'endpoint': 'http://localhost:8082',
      'region': 'eu-west-1',
      'custom_header': () => {
        return { 'X-API-KEY': window.localStorage.getItem('frmCloudExApiKey') }
      }
    },
    {
      'name': 'sql',
      'endpoint': 'http://localhost:8082',
      'region': 'eu-west-1',
      'custom_header': () => {
        return { 'X-API-KEY': window.localStorage.getItem('frmCloudExApiKey') }
      }
    }
  ]
}

const awsmobileExternal = {
  'aws_project_region': 'us-east-1',
  'aws_cloud_logic_custom': [
    {
      'name': 'cosmos',
      'endpoint': Config.API_SQL_ENDPOINT,
      'region': 'eu-west-1',
      'custom_header': () => {
        return { 'X-API-KEY': window.localStorage.getItem('frmCloudExApiKey') }
      }
    },
    {
      'name': 'sql',
      'endpoint': Config.API_SQL_ENDPOINT,
      'region': 'eu-west-1',
      'custom_header': () => {
        return { 'X-API-KEY': window.localStorage.getItem('frmCloudExApiKey') }
      }
    }
  ]
}

let awsmobile = awsmobileCloud
if (Config.API === 'local') {
  awsmobile = awsmobileLocal
}
if (Config.API === 'localexternal') {
  awsmobile = awsmobileLocalExternal
}
if (Config.API === 'external') {
  awsmobile = awsmobileExternal
}
if (Config.API === 'frmcloud') {
  awsmobile = awsmobileFrmCloud
}

export default awsmobile
