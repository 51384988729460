// core-js to make it work in IE 11
// empty comment line
import 'core-js/stable'
// import 'core-js/modules/es.array.iterator'
// import 'core-js/modules/es.object.to-string'
// import 'core-js/modules/es.set'
// import 'core-js/modules/es.array.of'
import 'regenerator-runtime/runtime'
import 'intersection-observer'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Amplify, * as AmplifyModules from 'aws-amplify'
import { AmplifyPlugin } from 'aws-amplify-vue'
import awsExports from './aws-exports-manual'
import BootstrapVue from 'bootstrap-vue'

import config from './config/config'

Amplify.configure(awsExports)

Vue.config.productionTip = true

Vue.use(BootstrapVue)
Vue.use(AmplifyPlugin, AmplifyModules)

Vue.prototype.$config = config

Vue.prototype.$logger = new Amplify.Logger('app', 'ERROR') // ERROR DEBUG

const stats = {
  install: function (Vue, options) {
    const init = {
      session_short: 0,
      user: '',
      user_type: 0 // 0 - internal, 1 - external
    }
    const log = async function (payload) {
      if (Vue.prototype.$config.ENV === 'local') {
        console.log(payload)
      }
      await Vue.prototype.$Amplify.API.put('cosmos', '/log', { body: payload })
    }
    Vue.prototype.$stat = {
      log: function (payload) {
        payload.session_long = init.session_long
        payload.session_short = init.session_short
        payload.user = init.user
        payload.user_type = init.user_type
        log(payload)
      },
      setSessionLong: function (payload) {
        init.session_long = payload
      },
      setSessionShort: function (payload) {
        init.session_short = payload
      },
      setUser: function (payload) {
        init.user = payload
      },
      setUserType: function (payload) {
        init.user_type = payload
      }
    }
  }
}

Vue.use(stats)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
