<template>
  <b-container class="text-center">
    <h1 class="mb-4">loading</h1>
    <h1><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></h1>
  </b-container>
</template>

<script>
import { CognitoAuth } from 'amazon-cognito-auth-js/dist/amazon-cognito-auth'
import Config from '../config/config'
let sjcl = require('sjcl')

export default {
  name: 'LoginCognitoAuth',
  data () {
    return {
      auth: ''
    }
  },
  created () {
    this.initCognitoSDK()
  },
  methods: {
    initCognitoSDK: function () {
      console.log('LoginCognitoAuth: initCognitoSDK')
      let authData = {
        ClientId: this.$config.AWS_CLIENT_ID,
        AppWebDomain: this.$config.AWS_APP_WEB_DOMAIN,
        TokenScopesArray: this.$config.AWS_TOKENSCOPESARRAY,
        RedirectUriSignIn: this.$config.AWS_REDIRECT_URI_SIGN_IN,
        RedirectUriSignOut: this.$config.AWS_REDIRECT_URI_SIGN_IN,
        // IdentityProvider : 'Cognito User Pool',
        UserPoolId: this.$config.AWS_USER_POOL_ID
        // AdvancedSecurityDataCollectionFlag: true
      }
      this.auth = new CognitoAuth(authData)
      this.auth.userhandler = {
        onSuccess: function (result) {
          // console.log('LoginCognitoAuth, onSuccess: login successful')
          // getting tokens
          let accessToken = result.getAccessToken().getJwtToken()
          let idToken = result.getIdToken().getJwtToken()
          let refreshToken = result.getRefreshToken().getToken()

          // saving tokens to local storage
          window.localStorage.setItem('accessToken', accessToken)
          window.localStorage.setItem('idToken', idToken)
          window.localStorage.setItem('refreshToken', refreshToken)

          // getting information about the user from the token - using sjcl to decode from base64
          let idTokenPayload = idToken.split('.')[1]
          let payload = JSON.parse(sjcl.codec.utf8String.fromBits(sjcl.codec.base64url.toBits(idTokenPayload)))
          // console.log('id token decoded content, payload:')
          // console.log(payload)
          let userGroup = payload['cognito:groups']
          if (userGroup && userGroup.length > 0) {
            window.localStorage.setItem('userGroup', userGroup)
          } else {
            userGroup = 'clientGroup'
            window.localStorage.setItem('userGroup', userGroup)
          }
          let userSub = payload['sub']
          // console.log('setting local storage: userSub, userState, username')
          let username
          if (payload['email']) {
            username = payload['email'].toLowerCase()
          } else {
            username = payload['cognito:username']
          }

          // setting some parameters for my application - these are watched by Vue to verify if the use is signed in
          window.localStorage.setItem('userSub', userSub)
          window.localStorage.setItem('userState', 'signedIn')
          window.localStorage.setItem('username', username)
          window.localStorage.setItem('userDate', Date.now())
          console.log('all logged in')
          console.log(Config.BASE_URL)
          if (window.localStorage.getItem('frmCloudRedirect')) {
            const target = window.localStorage.getItem('frmCloudRedirect')
            window.localStorage.removeItem('frmCloudRedirect')
            window.location.assign(target)
          } else {
            window.location.assign(Config.BASE_URL)
          }
        },
        onFailure: function (err) {
          // not doing anytnig on failure - Vue will check 'userState' in localstorage and if not set to 'signIn' - will treat as user never signed in...
          alert('Error!' + err)
        }
      }
      this.auth.useCodeGrantFlow()
      if (this.$route.params.action === 'signin') {
        this.auth.getSession()
      }
      if (this.$route.params.action === 'callback') {
        let curUrl = window.location.href
        this.auth.parseCognitoWebResponse(curUrl)
      }
      if (this.$route.params.action === 'signout') {
        console.log('signing out')
        window.localStorage.setItem('userState', '')
        window.localStorage.setItem('accessToken', '')
        window.localStorage.setItem('idToken', '')
        window.localStorage.setItem('refreshToken', '')
        window.localStorage.setItem('userGroup', '')
        window.localStorage.setItem('userSub', '')
        window.localStorage.setItem('userDate', '')
        window.localStorage.setItem('username', '')
        this.auth.signOut()
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
body {
  padding-top: 130px;
}
</style>
